import React from "react"

import Home from "./home"
import "./global.scss"

//import "../i18n"

const IndexPage = () => {
  return <Home />
}

export default IndexPage
